var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$can('create', 'firmwaremanagement'))?_c('b-sidebar',{attrs:{"id":"add-new-firmware-sidebar","visible":_vm.isAddNewFirmwareSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-add-new-firmware-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Add New Firmware ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validateForm)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-form-group',{attrs:{"label":"Version","label-for":"version"}},[_c('validation-provider',{attrs:{"name":"version","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"version","state":errors.length > 0 ? false:null,"name":"version"},model:{value:(_vm.firmwareData.version),callback:function ($$v) {_vm.$set(_vm.firmwareData, "version", $$v)},expression:"firmwareData.version"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Device","label-for":"device"}},[_c('validation-provider',{attrs:{"name":"device","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.deviceList,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"device"},model:{value:(_vm.firmwareData.device),callback:function ($$v) {_vm.$set(_vm.firmwareData, "device", $$v)},expression:"firmwareData.device"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"File (.tar.gz)","label-for":"theFile"}},[_c('validation-provider',{attrs:{"name":"theFile","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"theFile","accept":".gz","placeholder":"Choose a file or drop it here..."},model:{value:(_vm.firmwareData.theFile),callback:function ($$v) {_vm.$set(_vm.firmwareData, "theFile", $$v)},expression:"firmwareData.theFile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Checksum","label-for":"checksum"}},[_c('validation-provider',{attrs:{"name":"checksum","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"checksum"},model:{value:(_vm.firmwareData.checksum),callback:function ($$v) {_vm.$set(_vm.firmwareData, "checksum", $$v)},expression:"firmwareData.checksum"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"d-flex mt-2"},[(_vm.$can('create', 'firmwaremanagement'))?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Add ")]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Cancel ")])],1)],1)]}}],null,true)})]}}],null,false,874233599)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }