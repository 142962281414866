<template>
  <b-sidebar
    v-if="$can('create', 'firmwaremanagement')"
    id="add-new-firmware-sidebar"
    :visible="isAddNewFirmwareSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-firmware-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Firmware
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="observer"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(validateForm)"
          @reset.prevent="resetForm"
        >
          <!-- Version -->
          <b-form-group
            label="Version"
            label-for="version"
          >
            <validation-provider
              #default="{ errors }"
              name="version"
              rules="required"
            >
              <b-form-input
                id="version"
                v-model="firmwareData.version"
                :state="errors.length > 0 ? false:null"
                name="version"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Field: Device -->
          <b-form-group
            label="Device"
            label-for="device"
          >
            <validation-provider
              #default="{ errors }"
              name="device"
              rules="required"
            >
              <v-select
                v-model="firmwareData.device"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="deviceList"
                :reduce="val => val.value"
                :clearable="false"
                input-id="device"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Field: File -->
          <b-form-group
            label="File (.tar.gz)"
            label-for="theFile"
          >
            <validation-provider
              #default="{ errors }"
              name="theFile"
              rules="required"
            >
              <b-form-file
                id="theFile"
                v-model="firmwareData.theFile"
                accept=".gz"
                placeholder="Choose a file or drop it here..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Field: Checksum -->
          <b-form-group
            label="Checksum"
            label-for="checksum"
          >
            <validation-provider
              #default="{ errors }"
              name="checksum"
              rules="required"
            >
              <b-form-input
                id="checksum"
                v-model="firmwareData.checksum"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-if="$can('create', 'firmwaremanagement')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { Storage, Auth } from 'aws-amplify'
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormFile,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { ref } from '@vue/composition-api'
import { required } from '@validations'
// import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

// Notification
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    BFormFile,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewFirmwareSidebarActive',
    event: 'update:is-add-new-firmware-sidebar-active',
  },
  props: {
    isAddNewFirmwareSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankfirmwareData: {
        version: '',
        device: null,
        checksum: '',
        theFile: [],
      },
      firmwareData: {},
    }
  },
  setup() {
    const deviceList = store.state.appSettings.firmware_devices

    return {
      deviceList,
    }
  },
  created() {
    this.firmwareData.device = this.deviceList[0].value
    this.blankfirmwareData.device = this.deviceList[0].value
  },
  methods: {
    validateForm() {
      console.log(this.firmwareData)
      if (ability.can('create', 'firmwaremanagement')) {
        this.$refs.observer.validate().then(success => {
          if (success) {
            // Storage.put('test.txt', 'Hello', {
            //   bucket: 'cancom-eyetrax-firmware',
            //   region: 'ca-central-1',
            //   identityPoolId: 'ca-central-1_Ao5uWjpYj',
            //   custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
            // })
            this.firmwareData.version = this.firmwareData.version.replace('v', '')
            this.firmwareData.version = this.firmwareData.version.replace('V', '')
            this.firmwareData.filename = `${this.firmwareData.device}/eyetrax_update_${this.firmwareData.version}.tar.gz`

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Firmware is being uploaded. Please do not leave this screen.',
                icon: 'FilePlusIcon',
                variant: 'success',
              },
            })

            Storage.put(this.firmwareData.filename, this.firmwareData.theFile, {
              bucket: process.env.VUE_APP_AMPLIFY_FIRMWARE_STORAGE_AWSS3_BUCKET,
              region: process.env.VUE_APP_AMPLIFY_FIRMWARE_STORAGE_AWSS3_REGION,
              identityPoolId: 'ca-central-1_Ao5uWjpYj',
              custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
            })
              .then(result => {
                console.log(result)
                console.log(this.firmwareData)
                const qvars = {
                  device: this.firmwareData.device,
                  version: this.firmwareData.version,
                  checksum: this.firmwareData.checksum,
                  filesize: String(this.firmwareData.theFile.size),
                  filename: this.firmwareData.filename,
                }
                console.log('app-firmware/addFirmware')
                console.log(qvars)

                store.dispatch('app-firmware/addFirmware', qvars)
                  .then(() => {
                    this.$emit('refetchdata')
                    this.$emit('update:is-add-new-firmware-sidebar-active', false)
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Firmware uploaded successfully.',
                        icon: 'FilePlusIcon',
                        variant: 'success',
                      },
                    })
                  })
                  .catch(error => {
                    console.log(error)
                    let theErrorStr = 'The following error(s) where received:\n'
                    if (typeof error.response.data.errors !== 'undefined') {
                      error.response.data.errors.forEach(item => {
                        theErrorStr += `${item.message}\n`
                      })
                    }
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Error adding firmware information.',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: theErrorStr,
                      },
                    })
                  })
              })
              .catch(err => {
                console.log(err)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error Uploading File.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
      }
    },
    resetForm() {
      this.firmwareData = JSON.parse(JSON.stringify(this.blankfirmwareData))
      console.log(this.blankfirmwareData)
      console.log(this.firmwareData)
      this.$refs.observer.reset()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
