import { API } from 'aws-amplify'
import store from '@/store'
import ability from '../../../libs/acl/ability'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchFirmwareList(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'firmwaremanagement')) {
          console.log('fetchFirmwareList')
          console.log(qvars)
          const { forceRefresh } = qvars
          store.state.firmware.fetchingFirmwareList = true
          const d1 = new Date()
          const timeDiff = d1.getTime()

          let totalItems = 0

          const resortItems = theItems => {
            console.log('resort firmware')
            console.log(theItems)
            // sort the items
            let theSortBy = qvars.sortBy
            if (theSortBy === 'date_uploaded') {
              theSortBy = 'dt_created'
            }
            if (theSortBy === '') {
              theSortBy = 'dt_created'
              // eslint-disable-next-line no-param-reassign
              qvars.sortDesc = true
            }
            if (qvars.sortDesc) {
              theItems.sort((a, b) => {
                console.log('sort anything desc')
                return b[theSortBy].localeCompare(a[theSortBy])
              })
            } else {
              theItems.sort((a, b) => {
                console.log('sort anything asc')
                return a[theSortBy].localeCompare(b[theSortBy])
              })
            }
          }
          const paginateItems = theItems2 => {
            let myReturn = {}
            if (qvars.perPage > 0) {
              const start = (qvars.page - 1) * qvars.perPage
              const end = start + qvars.perPage
              myReturn = theItems2.slice(start, end)
            } else {
              myReturn = theItems2
            }
            return myReturn
          }
          const resortFilterItems = firmware => {
            const resultfirmware = []
            // first apply filters
            firmware.forEach(item => {
              let addThisItem = true
              if (qvars.q !== null
                && !item.version.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.device.includes(qvars.q.toUpperCase())) {
                addThisItem = false
              }
              if (addThisItem) {
                resultfirmware.push(item)
              }
            })
            totalItems = resultfirmware.length
            // resort firmware
            resortItems(resultfirmware)
            // Apply pagination and return
            return paginateItems(resultfirmware)
          }

          // Check for caching
          let doRefresh = false
          if (forceRefresh) {
            doRefresh = true
          } else if (localStorage.getItem('firmware') === null || parseInt(localStorage.getItem('firmware_lastRefresh'), 10) + 1440000 < timeDiff) { // cache for 24 hours
            doRefresh = true
          }
          if (doRefresh) {
            console.log('Refreshing Firmware List')
            const apiName = 'APIGateway'
            const path = `/firmware/${qvars.deviceType}`
            const myInit = { response: true }

            API.get(apiName, path, myInit)
              .then(response => {
                console.log(response)
                const theResponse = response
                const items = response.data
                totalItems = items.length
                // Store User data in local storage
                localStorage.setItem('firmware', JSON.stringify(items))
                localStorage.setItem('firmware_lastRefresh', d1.getTime())
                theResponse.data.items = resortFilterItems(items)
                theResponse.data.totalItems = totalItems
                // console.log(theResponse)
                store.state.firmware.fetchingFirmwareList = false
                resolve(theResponse)
              })
              .catch(error => {
                store.state.firmware.fetchingFirmwareList = false
                reject(error)
                if (error === 'No current user') {
                  this.$router.replace({ name: 'auth-login' })
                }
              })
          } else {
            console.log('Using Cached Firmware Data')
            const theResponse = { data: {} }
            let items = []
            items = JSON.parse(localStorage.getItem('firmware'))
            totalItems = items.length
            theResponse.data.items = resortFilterItems(items)
            theResponse.data.totalItems = totalItems
            // console.log(theResponse)
            store.state.firmware.fetchingFirmwareList = false
            resolve(theResponse)
          }
        } else {
          reject()
        }
      })
    },
    fetchFirmwareDetails(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'firmwaremanagement')) {
          const apiName = 'APIGateway'
          const path = `/firmware/${qvars.device}/${qvars.version}`

          API.get(apiName, path)
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              console.log(error)
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    addFirmware(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('create', 'firmwaremanagement')) {
          const apiName = 'APIGateway'
          const path = '/firmware'

          API.post(apiName, path, { body: qvars })
            .then(response => {
              // Add new user to the local storage to avoid call to backend
              const newItems = JSON.parse(localStorage.getItem('firmware'))
              if (newItems !== null) {
                newItems.push(qvars)
                localStorage.setItem('firmware', JSON.stringify(newItems))
              }
              resolve(response)
            })
            .catch(error => {
              console.log(error)
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    deleteFirmware(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('delete', 'firmwaremanagement')) {
          const apiName = 'APIGateway'
          const path = '/firmware'

          API.del(apiName, path, { body: qvars })
            .then(response => {
              // Update local storage to avoid call to backend
              const newItems = JSON.parse(localStorage.getItem('firmware'))
              const tmp = []
              if (newItems !== null) {
                newItems.forEach(item => {
                  if (
                    item.version === qvars.verion
                    && item.device === qvars.device
                  ) {
                    // don't add the item as this is the one that was deleted
                  } else {
                    tmp.push(item)
                  }
                })
                localStorage.setItem('firmware', JSON.stringify(tmp))
              }
              resolve(response)
            })
            .catch(error => {
              console.log(error)
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
  },
}
