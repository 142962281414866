import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '../../../../libs/acl/ability'

// import { API } from 'aws-amplify'
// import AWSAuth from '@/auth/aws/useAWS'

export default function useFirmwareList() {
  // Use toast
  const toast = useToast()

  const refFirmwareListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'version', sortable: true },
    { key: 'device', sortable: true },
    { key: 'checksum', sortable: true },
    { key: 'date_uploaded', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('version')
  const isBusy = ref(false)
  const isSortDirDesc = ref(true)
  const deviceType = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refFirmwareListTable.value ? refFirmwareListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refFirmwareListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, deviceType], () => {
    refetchData()
  })

  const fetchFirmware = (ctx, callback) => {
    console.log('fetchFirmware')
    if (ability.can('read', 'firmwaremanagement')) {
      const qvars = {
        forceRefresh: store.state.firmware.fetchingFirmwareList,
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        deviceType: deviceType.value,
      }
      console.log(qvars)
      store
        .dispatch('app-firmware/fetchFirmwareList', qvars)
        .then(response => {
          console.log(response)
          const { items } = response.data
          callback(items)
          totalItems.value = response.data.totalItems
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Firmware list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  }

  return {
    fetchFirmware,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isBusy,
    isSortDirDesc,
    refFirmwareListTable,
    refetchData,
    deviceType,
  }
}
