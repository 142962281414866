<template>

  <div
    v-if="$can('read', 'firmwaremanagement')"
    class="mb-2"
  >

    <firmware-list-add-new
      :is-add-new-firmware-sidebar-active.sync="isAddNewFirmwareSidebarActive"
      @refetchdata="refreshFirmwareList"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <!-- Filters -->
      <firmware-list-filters
        :device-type.sync="deviceType"
        :device-type-options="deviceTypeOptions"
      />
      <!-- Table Top -->
      <div class="m-2">
        <!-- <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              class="mr-2"
              variant="primary"
              :disabled="this.$store.state.firmware.fetchingFirmwareList"
              @click="refreshFirmwareList"
            >
              <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh List</span>
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div
              v-if="$can('create', 'firmwaremanagement')"
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                variant="primary"
                @click="isAddNewFirmwareSidebarActive = true"
              >
                <feather-icon icon="FilePlusIcon" /><span class="text-nowrap ml-1">Add Firmware</span>
              </b-button>
            </div>
          </b-col>
        </b-row> -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
            <b-button
              class="ml-2"
              variant="primary"
              :disabled="this.$store.state.firmware.fetchingFirmwareList"
              @click="refreshFirmwareList"
            >
              <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh</span>
            </b-button>
            <b-button
              class="ml-2"
              variant="primary"
              @click="isAddNewFirmwareSidebarActive = true"
            >
              <feather-icon icon="FilePlusIcon" /><span class="text-nowrap ml-1">Add Firmware</span>
            </b-button>
          </b-col>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refFirmwareListTable"
        class="position-relative"
        :items="fetchFirmware"
        responsive
        :fields="tableColumns"
        primary-key="version"
        :sort-by.sync="sortBy"
        show-empty
        empty-text=""
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >

        <!-- Column: Firmware Version -->
        <template #cell(version)="data">
          <b-media vertical-align="center">
            {{ data.item.version }}
          </b-media>
        </template>

        <!-- Column: Device -->
        <template #cell(device)="data">
          <div class="text-nowrap">
            <span>{{ data.item.device }}</span>
          </div>
        </template>

        <!-- Column: Checksum -->
        <template #cell(checksum)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.checksum }}</span>
          </div>
        </template>

        <!-- Column: Checksum -->
        <template #cell(date_uploaded)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.dt_created }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="$can('read', 'firmwaremanagement')"
              @click="downloadFirmware(data.item)"
            >
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">Download</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('delete', 'firmwaremanagement')"
              @click="deleteFirmware(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
// import { Storage, Auth } from 'aws-amplify'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useFirmwareList from './useFirmwareList'
import firmwareStoreModule from '../firmwareStoreModule'
import FirmwareListAddNew from './FirmwareListAddNew.vue'
import FirmwareListFilters from './FirmwareListFilters.vue'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    FirmwareListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BDropdown,
    BDropdownItem,
    BPagination,
    FirmwareListFilters,

    vSelect,
  },
  props: {
  },
  data() {
    return {
      clients: [],
      clientsList: [],
    }
  },
  computed: {
    isFetchingFirmwareList() {
      console.log('isFetchingFirmwareList')
      console.log(this.$store.getters.getFetchingFirmwareList)
      return this.$store.getters.getFetchingFirmwareList
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-firmware'
    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, firmwareStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const isAddNewFirmwareSidebarActive = ref(false)

    const deviceTypeOptions = store.state.appSettings.firmware_devices

    const {
      fetchFirmware,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refFirmwareListTable,
      refetchData,
      deviceType,

    } = useFirmwareList()

    deviceType.value = deviceTypeOptions[0].value

    return {

      // Sidebar
      isAddNewFirmwareSidebarActive,

      fetchFirmware,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refFirmwareListTable,
      refetchData,
      deviceType,
      deviceTypeOptions,

      // Filter
      avatarText,
    }
  },
  methods: {
    refreshFirmwareList() {
      console.log('refreshFirmwareList')
      this.$store.state.firmware.fetchingFirmwareList = true
      this.refetchData()
    },
    downloadFirmware(item) {
      console.log(item)
      console.log(item.access_url)
      if (ability.can('read', 'firmwaremanagement')) {
        const qvars = {
          device: item.device,
          version: item.version,
        }
        console.log(qvars)
        store
          .dispatch('app-firmware/fetchFirmwareDetails', qvars)
          .then(response => {
            console.log(response)
            console.log(response.presigned_url)
            // if we got the presigned URL use it to download
            if (typeof response.presigned_url !== 'undefined') {
              const link = document.createElement('a')
              link.download = `eyetrax_update_${item.version}.tar.gz`
              link.href = response.presigned_url
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error fetching Firmware download details',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(error => {
            console.log(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching Firmware details',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })

        // const result = Storage.get(item.access_url, {
        //   bucket: 'cancom-eyetrax-firmware',
        //   region: 'ca-central-1',
        //   identityPoolId: 'ca-central-1_Ao5uWjpYj',
        //   custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
        //   download: true,
        // })
        //   .then(result1 => {
        //     console.log(result1)
        //     console.log(result)
        //     const fileUrl = window.URL.createObjectURL(result1.Body)
        //     const fileLink = document.createElement('a')
        //     fileLink.href = fileUrl
        //     fileLink.setAttribute('download', `eyetrax_update_${item.version}.tar.gz`)
        //     document.body.appendChild(fileLink)
        //     fileLink.click()
        //   })
      }
    },
    deleteFirmware(item) {
      console.log(item)
      if (ability.can('delete', 'firmwaremanagement')) {
        this.$bvModal
          .msgBoxConfirm(`Please confirm that you want to delete v${item.version} of the ${item.device} firmware.`, {
            title: 'Delete Firmware',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              // proceed with delete
              const qvars = {
                device: item.device,
                version: item.version,
              }
              console.log(qvars)
              store
                .dispatch('app-firmware/deleteFirmware', qvars)
                .then(response => {
                  console.log(response)
                  this.refreshFirmwareList()
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Firmware version deleted successfully.',
                      icon: 'FileMinusIcon',
                      variant: 'success',
                    },
                  })
                })
                .catch(error => {
                  console.log(error)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error deleting firmware version.',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
            }
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
